.mainTitle{
    color: #FFF;
    font-size: 100px;
    font-weight: 700;
}

.subTitle{
    color: #FFF;
    font-size: 50px;
    font-weight: 700;
}

.footerText{
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    position: absolute;
    bottom: 60px;
}